.container {
  display: flex; 
  justify-content: center;
  align-items: center;
 
}


.toggle-container {
  position: relative;
  top: 0;
  width: 100%;  
}

/* .form-container {
  padding: 30px;  
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  margin: 0 auto; /* Added to center the form horizontally 
} */

/* Slider styles */
.slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: var(--accent-5);
}

.slider-text {
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--accent-4);
  transition: 0.4s;
  border-radius: 34px;
  border: 1px solid var(--accent-2);
}

.slider-toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--accent-5);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-toggle {
  background-color: var(--accent-2);
}

input:checked + .slider-toggle:before {
  transform: translateX(26px);
}

.slider-toggle.round {
  border-radius: 34px;
}

/* Form styles */
.sign-in-form,
.sign-up-form {
  display: block;
}

.signIn_header-text, .signUp_header-text{
color: var(--accent-5);
margin-bottom: 20px ;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-group input {
  margin-right: 5px;
  color: var(--font-color-2);
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
select {
  width: 100%;
  /* padding: 6px; */
  border: 1px solid #ccc;
  /* border-radius: 5px; */
  font-size: 16px;
}

 
input[type="checkbox"] {
  margin-right: 10px;
}

.rememberMe_label {
color: var(--accent-5);
}
 
.form-group, .remember-me-label {
  color: var(--font-color-2);
  margin-left: 5px;
  padding: 5px;  
}

.forgotPassword_link{
 color: var(--accent-2);
 text-decoration: underline;
}

/* button[type="submit"] {
  padding: 8px 12px;
  background: var(--background-3);
  font-size: var(--font-size-5);
  color: var(--font-color-2);
  margin: 1rem 0px;
  border: none;
  width: 100%;
  cursor: pointer;
  transition: 0.8s;
  font-weight: var(--font-weight-2);
} */


/* use this stryling??---------------------------- */
.signIn_button:hover,
.signUp_button:hover {
  background: var(--background-2);
  color: var(--font-color-2);
}

.social-login {
  /* display: flex;
  justify-content: space-between;
 flex-wrap: nowrap;  
  overflow-x: auto; */
  margin: 30px 5px;
}

/* --------------------------------------------------------- New --------------  */

/* .sign-in-form{
border: 10px solid yellow;
} */

 

/* .google-btn {
  background-color: var(--background-1);
  color: #fff;
  /* border-radius: 50%; 
} */

/* .google-logo{
 width:25px;
 height: 30px;   
} */





/* Hover styles in here  */

/* Responsive styles */
@media (max-width: 280px) {
  .form-container {
    padding: 20px;
  }

  .social-login {
    flex-direction: column;
  }

  .social-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}


/* --------------------------------------------------------------------------sign out -------------- */

/* .profile_page-container
.profile-page__picture-container
.profile-page__picture

.sign_out-btn */


.profile-page__picture-container{
    /* margin: auto; */
    /* display: block; */
    /* position: relative;
    width: 100%; */
    margin-bottom: 10px;
    margin-top: -70px;
}

.profile-page__picture{
  width: 100px;  
  height: 100px;
  position: relative;
  /* display: inline-block; */
  border-radius: 50%;
  border: 3px solid white;
}


sign_out-btn{
  position: relative;
  width: 120px;
  height: 40px;
  background-color: white;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  font-family: "VT323";
  /* border: 1px solid red; */
  font-size: 25px;
  margin-top: 30px;

}

sign-in-info{
  position: relative;
  /* width: 100%; */
  height: 40px;
  display: block;
  color: white;

  font-family: "VT323";
  /* border: 1px solid red; */
  font-size: 25px;
}
profile-page{
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  position: absolute;
  /* display: block; */
  padding: 50px;
  /* float: left; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
} 

/* ----------------------------------- new logo ------------------------------------------ */
.cardP {
  color: var(--font-color-2);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


.Card__logo {
  display: relative;
  height: 15rem;
  /* filter: drop-shadow(0.5rem 0.4rem 0.3rem var(--accent-3)); */
}

.SignUp_Card__text-gameName {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-1);
  display: block;
  position: absolute;
  /* top:0;
  left:0; */
  /* top: 38%;
  left: 50%;
  transform: translate(-50%, -50%); */
  z-index: 1;
  white-space: nowrap;  
  text-overflow: ellipsis;  
  /* max-width: 80%;   */

  /* font-family: "VT323"; */
  
  filter: drop-shadow(5px 5px 15px black);
}

.SignUp_Card__text-SubTitle {
  font-family:"Roboto";
  font-size: 30px;
}


/* ---------------------------------------------buttons--------------------- */

.social-login {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Adjust this value to control space between buttons */
}

/* 
.discord-login {
  flex: 0 0 auto; /* Prevents the buttons from growing or shrinking 
} */


.google-btn,
.discord-login {
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-1);
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.google-logo,
.discord-logo {
  width: 30px;
  height: 30px;
}