/* App.css for other backgrounds?--------------------------------------- */

#root{

  overflow: hidden;
}

.card-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
*{
  overflow-y: none;
}

.card-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #292727;
  /* background: linear-gradient(to bottom,rgba(255, 255, 255, 0.8), rgba(0, 255, 255, 0.8)); */
  z-index: 0;
  animation: gradientAnimation 7s infinite ease-in-out alternate;
}


@keyframes gradientAnimation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.homeContainer {
  color: var(--font-color-2);
  text-align: center;
}
.homeTitle{
  margin-top: -100px;
}
.Card__logo {
  display: relative;
  text-align: center;
  position: relative;
}

.Card__logo > img {
  display: relative;
  margin-top: -5rem;
  height: 15rem;
  filter: drop-shadow(0.5rem 0.4rem 0.3rem var(--accent-3));
}

.Card__text-gameName {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-1);
  display: block;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  white-space: nowrap;  
  text-overflow: ellipsis;  
  max-width: 80%;  
  filter: drop-shadow(0.3rem 0.3rem 0.3rem var(--accent-3));
}

/* animation: textAnimation 7s infinite ease-in-out alternate; */

/* @keyframes textAnimation {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
} */

.Card__text-SubTitle {
 font-size: calc(var(--font-size-6) * 0.6);
  position: fixed;  
  top: 41%; 
  right: 15%;
  z-index: 2;   
  filter: drop-shadow(0.3rem 0.3rem 0.3rem var(--accent-3));
}


.Card__text-developer {
  font: var(--font-family-1);
  font-size: var(--font-size-4);
  position: relative;
  margin-top: 1.5rem;
  display: block;
}

/* .Card__QR {
  display: relative;
}

.Card__QR > img {
  display: relative;
  margin-top: 1rem;
  height: 7rem;
} 

/* MEDIA  -------------------------------------------- */
