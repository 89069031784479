/* this is showing up but its extending the size of the page and is showing up at the bottom   */

.weather-component {
  display: flex;
  align-items: center;
  position: fixed; 
  top: 20px;  
  right: 20px; 
  z-index: 999;
  flex-direction: row-reverse;   
}

.weather-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000; 
}

/* .weather-icon-button  {
  width: 50px;
  height: 50px; 
} */

.weather-details {
  margin-right: 10px;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  z-index: 1000; 
}

.weather-details.show {
  max-height: 200px;
}
