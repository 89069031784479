inventory-elms{
  pointer-events: none;
}
item-container{
    position: absolute;
    width: 70px;
    padding-left: 8px;
    border-radius: 5px 0 0 5px;
    height: 60px;
    top:140px;
    right:0;
    /* border: 1px solid white; */
    z-index: 100000;
    float: left;
    
  
    /* transition: 0.1s ease-in-out; */
    pointer-events: all;
    /* background-image: url("lion.png"); */
    background-size: cover;
    image-rendering: pixelated;
    background-color: rgb(40,40,40);

  }
  item-inventory{

    height: fit-content;
    position: absolute;
    top:0;
    right:calc(100% + 10px);
  
    overflow: hidden;


    width: min-content;
    min-width: 195px;
    min-height: 60px;
    filter: brightness(120% );
    pointer-events: none;;
    background-color: rgb(40,40,40);
    padding: 5px;

    display: none;
    border-radius: 5px;
  } 
  .container-expanded > item-inventory{
    display: block;
  }
 
  close-container{
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;;
    color: white;;
    /* border: 1px solid red; */
    display: block;
    font-family: "VT323";
    font-size: 25px;
    opacity: 0;
  }
 
  .container-expanded{
    /* height: calc(60px * 6); */
    /* width: 244px; */
  
    background-image: none;
  
  }
#chest-elm{
    position: absolute;
    top:0;
    /* right:0; */
    /* border: 1px solid green; */
    width:60px;
    height:60px;
    pointer-events: all;
}
  .container-expanded #inventory{
    overflow-y: auto;
  }
  .container-expanded close-container{
    opacity: 1;;
  }
  
  .container-expanded #inventory * {
    pointer-events: all !important;
    opacity: 1;
  }
  *::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  *::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  inventory-item{
    width: 50px;
    height: 50px;
    margin: 5px;
    position: relative;
    /* border: 1px solid green; */
    display: inline-block;
    white-space:nowrap;
    /* background-color: rgba(40,40,40,1); */
    border-radius: 5px;
    color: white;
    /* pointer-events: all; */
    z-index: 100001;
  }
  item-popup-back{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left:0;
  background-color: rgba(0,0,0,0.4);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  }
  .hide {
    display: none;
  }
  
.item-scene{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

  inventory-elms{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    display:block
  }