.main__body-about {
  background: var(--background-4);
  min-height: 100vh;
  position: absolute;
  width: 100vw;

}

.main__about-header {
  font-size: var(--font-size-2);
  color: var(--font-semiTransparent);
  position: relative;
  margin: 6.5rem 0 0 3rem;
  /* top: 10rem auto; */
  /* text-align: center;   */
  z-index: 10;
  line-height: 100%;
  font-family: "VT323";
}

.accordion__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: crosshair;
  padding: 1.2rem;
  color: var(--font-color-2);
  background-color: var(--accent-1);
  border: 1px solid var(--background-3);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-3);
  font-family: "VT323";
}

.accordion__title:hover {
  background-color: var(--background-3);
  color: var(--font-color-1);
  transition: all 0.8s;
}
.tutorial-video{
  width: 100%;
  position: relative;
}
.accordion {
  /* margin-top: 150px; */
  position: relative;
  border: 1px solid var(--background-3);
  row-gap: 10px;
  font-size: var(--font-size-5);
  line-height: 1.2;
  margin-bottom:90px;
  /* display:inline-block; */
}

.accordion__content {
  padding: 1.2rem;
  color: var(--font-color-2);
}

/* content text  ------------------------------------- */
.content-text{
  padding: 10px 20px; 
}

.content-email, .content-web-link {
    color: var(--accent-3);
  text-decoration: underline;
}

.accordion_logo{
  display: flex;
  align-items: center;
  margin-bottom: 10px;

}

.logo {
  width: 30px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px;
}


/* MEDIA  -------------------------------------------- */

@media screen and (min-width: 700px) {
  .main__body-about {
  padding: 0px 15%;
}
  .main__about-header {  
    margin: 7rem 3rem;
    font-size: 3rem;
  }

  .accordion__title {
    font-size: 1.4rem;
  }

  .accordion {
    margin: 12rem 3rem;
    font-size: 1.4rem;
  }
}
