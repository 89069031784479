.main {
  position: fixed;  
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  z-index: 1000; 
  bottom: 20px;  
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.menu__trigger,.menu__item  {
  width: 40px;
  height: 40px;
  } 

.menu__trigger {
  background-color: var(--background-3);
  /* border: var(--border-style-1); */
  padding: 8px;
  cursor: pointer;
  transition: 0.35s ease;
   z-index: 1000; 
   border-radius: 50px;
}

/* Menu Icons */
.menu__item {
  position: absolute;
  padding: 10px;
  background-color: var(--accent-5);
  border: var(--border-style-1);
  z-index: -500;  
  opacity: 0;
  bottom: .1px;   
  border-radius: 50px;  

}

/* Transition duration delay */
.menu__item--0 {
  transition: 0.35s ease;
  left: calc(50% - 30px);  
}

.menu__item--1 {
  transition: 0.35s ease 0.1s;
  left: calc(50% - 30px);
}

.menu__item--2 {
  transition: 0.35s ease 0.2s;
  right: calc(50% - 30px);
}

/* .menu__item--3 {
  transition: 0.35s ease 0.3s;
  right: calc(50% - 30px);
} */

.menu__item--4 {
  transition: 0.35s ease 0.3s;
  right: calc(50% - 30px);
}


.is-rotate {
  transform: rotateZ(360deg);
  /* background-color: var(--accent-2);
  border: var(--border-style-1); */
}

/* Positioning the menu item */
.item-0 {
  left: calc(50% - 100px);
  opacity: 1;
}

.item-1 {
  left: calc(50% - 60px);
  opacity: 1;
}

/* developer page for navigation */
.item-5 {
  left: calc(50% - 60px);
  opacity: 1;
}

.item-2 {
  right: calc(50% - 60px);
  opacity: 1;
}

/* .item-3 {
  right: calc(50% - 100px);
  opacity: 1;
} */

.item-4 {
  right: calc(50% - 100px);
  opacity: 1;
}

.navHide{
  display: none;
}