@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono);

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;  
}
 
:root {
  --background-1: #ffff;
  --background-2: #471e3b;
  --background-3: #fff313;
  --background-4: #202020;
  --background-5: #00ff00;
  /* --background-6: url(./components/accimg/Nav_Icons/grid.png); */

  --accent-1: #8d8b0dcc;
  --accent-2: #e8e517cc;
  --accent-3: #00ff00;
  --accent-4: #545455;
  --accent-5: #ffff;
  --accent-6: #000;
  --accent-7: #f509ae;
  --accent-8: #0059d3;
  

  /* scale from mobile to desktop base 16 */
  

  
--font-size-1: 3.4375rem; /* H1 | 55px / 16px- 2-3.4375rem (32-55px) (default browser font-size) */
--font-size-2: 2.75rem; /* H2 | 44px / 16px - 1.5-2.75rem (24-44px) */
--font-size-3: 2rem; /* H3 | 32px / 16px - 1.25-2rem (20-32px) */
--font-size-4: 1.5rem; /* H4 | 24px / 16px - 1-1.5rem (16-24px) */
--font-size-5: 1.25rem; /* H5 | 20px / 16px - 0.75-1.25rem (12-20px) */
--font-size-6: 0.8rem; /*H6 | 16px / 16px - 0.67-1rem (10.72-16px)*/


  --font-weight-1: 700;
  --font-weight-2: 600;
  --font-weight-3: 400;

  --font-color-1:  #424d5c;
  --font-color-2: #ffff;
  --font-color-3: #000;
  --font-color-4: #d7d7db;
  --font-color-5: #00ff00;
  --font-semiTransparent: #ffffff80;

  /* styling */
  --box-shadow-1: 6px 6px 6px 0px var(--accent-1);
  --box-shadow-2: 0 12px 12px var(var(--accent-3));
  --box-shadow-3: 0 0 6px #fb00ff99;

  /* Border */
  --border-color-1: #888787;
  --border-color-2: #fb00ff99;

  --border-style-1: 1px solid #d3d3d3;
  --border-radius-1: 20px;

  --border-radius-2: 3px;

  /* width: 100%;
  height: 100%;
  position: fixed; */
  /* overflow: none;; */
}

/* No global styling in here   */


html {
  color-scheme: dark light;
}
 
.Card__b {
  height: 300px;
  width: 16rem;
  margin: 0 auto;
}

.newMap{
  position: absolute !important;
  width: 100% !important;
  height: 100%  !important;
  /* border: 1px solid red;; */
  top:0;
  left:0;
  z-index: -1;
}
.Grid__container-b {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));

  /* grid-auto-rows:20rem ; */
  /* border: 3px solid pink */
}

/* Projects  ---------------*/
.Grid__container-e {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  margin: 50px 10px;
  grid-auto-rows: (auto-fit, minmax(20rem, 2fr))
  
  
  /* minmax(20rem);   */
}



 .Site__width {
 width: calc(70vw + 10vw);
 margin: 0 auto;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  grid-auto-rows: minmax(10rem, 1fr); /* Minimum height of 10rem, maximum of 1fr */
}