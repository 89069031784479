/* canvas#bg{
  position: fixed;
  top:0;
  left:0;

  /* background-color: black; 
  z-index: 100;
  pointer-events: none;
} */

*{
  /* font-family: "Roboto"; take this out */
  padding:0;
  margin: 0;
}

/* #app {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
  border:none rgb(20, 20, 20);
  /* display: none; */
 /* #app > *{
  pointer-events: all;
} */
/* #bg{
  /* display: none; */
  /* opacity: 0.4; 
  background: none;
} */

/* eraser-tool{

  background-color: rgb(10,10,10);
  position: absolute;
  right: 100px;
  bottom: 100px;
  pointer-events: all;
  color: white;;
  
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  border-radius: 15px;
}
#color-input{
  color: black;
  z-index: 1000;
  position: absolute;
  right: 100px;
  bottom: 140px;
} */


/* #root - 
another name {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
} */

 /* body another name {
 font-family: "Share Tech Mono", monospace;
 color: var(--font-color-1);
 background-attachment: fixed;
 width: 100%;
 height: 100%;
 position: absolute;
 top:0;
 left:0;
}  */
 
#map-parent{
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;
}
#map-parent > encounter-container{
  pointer-events: all;
}
#map-parent engage-btn {
  pointer-events: all;
}
map-container{
  width: 100vw;
  height: 100vh;
  margin: 0;
  position: absolute;
  display: block;
  top:0;
  left:0;
  pointer-events:none;
  /* border: 5px solid red; */
}



.mapboxgl-marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border:1px solid var(--accent-4);
  /* background-color:lightblue; */
}
player-info{
  position: absolute;
  top:20px;
  right: 0;
  /* border: 1px solid red; */
  width: 300px;
  height: 100px;
  z-index: 10000;
}
health-container{
  width: 100%;
  height: 50px;
  /* border: 1px solid green; */
  position: relative;
  font-size: 40px;
  color:white;
  font-family: "VT323";
  /* display: inline-block;/ */
  float: right;
  display: flex;
  justify-content: right;
}
.encounter-heart{
  width: 32px;
  height: 32px;
  margin-left: 5px;
  margin-top: 10px;
  /* image-rendering: pixelated; */
  /* display: inline-block; */
  position: relative;
  /* top:-10px */
}
.enemy-icon{
  width: 50px;
  height: 50px;
  margin-left: 5px;
  /* image-rendering: pixelated; */
  position: relative;
  /* display: inline; */
}
.player-icon{
  width: 50px;
  height: 50px;
  margin-left: 5px;
  /* image-rendering: pixelated; */
  position: relative;
  /* display: inline; */
}
.mapboxgl-ctrl-geolocate{
  display: none !important;
}
#item-image{
  width: 300px;
height: 300px;

}
encounter-container{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 100000;
  display: none;;
  background-color: black;
}
#userVideo{
  position: absolute;
  top: 0;
  left: 0;
 
  width: 100%;
  height: 100%;
  /* border: 1px solid blue; */
  object-fit: cover;
}
.show{
  display: block !important;
}
info-box{
  width: 100px;
  height: 50px;
  z-index: 100000;
  /* border:1px solid blue; */
  background-color: white;;
  position: absolute;
  top:0;
  left:0;
}
#encounterCanvas{
  position: absolute;
  top: 0;
  left: 0;
 
  width: 100%;
  height: 100%;
  background: none;
  /* border: 1px solid red; */
  z-index: 100000;
}
encounter-ui{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  z-index: 100010;
  /* border: 1px solid green; */
  pointer-events: none;;

}
encounter-ui *{
  pointer-events: auto;;
}
engage-btn{
  width: 80px;
  height: 40px;
  position: absolute;
  background-color: white;
  font-family: "VT323";
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 25px;
  color: black;
  border-radius: 5px;
  bottom: 80px;
  left: calc(50% - 40px);

}
encounter-btn{
  position: absolute;
  width: 60px;
  height: 60px;
  top: 200px;
  right: 0px;
  display: block;
  /* z-index: 100010; */
  /* border: 1px solid white; */
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "VT323";
  border-radius: 5px;
}
encounter-hit-area{
  width: 500px;
  height: 150px;
  border: 3px solid white;
  position: absolute;
  bottom: 50px;
  left:calc(50% - 250px);
  pointer-events: all;
  display: none;
}

encounter-hit-bar{
  height: calc(100% + 20px );
  position: absolute;
  width: 4px;
  background-color: white;
  top:-20px;
}
encounter-reticle{
  width: 150px;
  height: 150px;
  position: absolute;
  background-color: none;
  left:calc(50% - 75px);
  top:calc(50% - 75px);

  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red;; */
}

attack-elm{
  width: 60px;
  height: 60px;
  background-color: white;
  position: absolute;
  left: calc(50% - 30px);
  bottom: 50px;
  pointer-events: all;
  z-index: 100000;
}

item-popup{
  width: 300px;
  height: 400px;
  background-color: rgb(40,40,40);
  color:white;;
  border-radius: 5px;
}
item-name{
width: 100%;
position: relative;
display: inline-block;
padding: 20px;
padding-bottom: 0;
font-size: 25px;
font-family: "VT323";
}

item-description{
  width: 100%;
  position: relative;
  display: inline-block;
 
  padding: 20px;
  padding-top: 5px;
  font-family: "VT323";
}
.mapboxgl-marker{
  background-color: none !important;
}

/* Mapbox branding ----------------------------------------------*/
.mapboxgl-ctrl-logo {
  width: 0px;
  height: 0px;
}

a.mapboxgl-ctrl-logo {
  width: 0px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl-attrib-button {
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background-color: 0;
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background-color: 0;
  border-radius: 0px;
  margin: 0px;
  min-height: 0px;
  padding: 0px;
  width: 0px;
  height: 0px;
}

.MarkerText {
  color: #fff;
  display: none;
}

/* Mapbox logo */
a.mapboxgl-ctrl-logo {
    /* background-image: url(""); */
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
    height: 0px;
    margin: 0 0 ;
    overflow: hidden;
    width: 0px;
}
result-back{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.4)
  ;
  top:0;
  left:0;
  z-index: 200000;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  display: none;
}
result-box{
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -50px;
  position: absolute;
  /* border: 1px solid green; */
  display: none;;
}
.resultShow{
  display: flex;
}
 result-text{
  font-family: "VT323";
  font-size: 50px;
}
#resultWin result-text{
  color: rgb(40,255,40);
}

#resultLose result-text{
  color: rgb(255,40,40);
}
/* ---------------------------------------------------------- */